define("@ember-data/graph/-private", ["exports", "@ember/debug", "@ember-data/store/-private", "@embroider/macros/runtime"], function (_exports, _debug, _private, _runtime) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.graphFor = graphFor;
  _exports.isBelongsTo = isBelongsTo;
  _exports.peekGraph = peekGraph;
  function coerceId(id) {
    if ((0, _runtime.macroCondition)((0, _runtime.config)("/home/tj/crimeisdown-v3/node_modules/@ember-data/graph").deprecations.DEPRECATE_NON_STRICT_ID)) {
      let normalized;
      if (id === null || id === undefined || id === '') {
        normalized = null;
      } else {
        normalized = String(id);
      }
      (true && !(normalized === id) && (0, _debug.deprecate)(`The resource id '<${typeof id}> ${String(id)} ' is not normalized. Update your application code to use '${JSON.stringify(normalized)}' instead.`, normalized === id, {
        id: 'ember-data:deprecate-non-strict-id',
        until: '6.0',
        for: 'ember-data',
        since: {
          available: '5.3',
          enabled: '5.3'
        }
      }));
      return normalized;
    }
    (true && !(id === null || typeof id === 'string' && id.length > 0) && (0, _debug.assert)(`Resource IDs must be a non-empty string or null. Received '${String(id)}'.`, id === null || typeof id === 'string' && id.length > 0));
    return id;
  }
  function getStore(wrapper) {
    (true && !('_store' in wrapper) && (0, _debug.assert)(`expected a private _store property`, '_store' in wrapper));
    return wrapper._store;
  }
  function expandingGet(cache, key1, key2) {
    const mainCache = cache[key1] = cache[key1] || Object.create(null);
    return mainCache[key2];
  }
  function expandingSet(cache, key1, key2, value) {
    const mainCache = cache[key1] = cache[key1] || Object.create(null);
    mainCache[key2] = value;
  }
  function assertValidRelationshipPayload(graph, op) {
    const relationship = graph.get(op.record, op.field);
    (true && !(isHasMany(relationship) || isBelongsTo(relationship)) && (0, _debug.assert)(`Cannot update an implicit relationship`, isHasMany(relationship) || isBelongsTo(relationship)));
    const payload = op.value;
    const {
      definition,
      identifier,
      state
    } = relationship;
    const {
      type
    } = identifier;
    const {
      field
    } = op;
    const {
      isAsync,
      kind
    } = definition;
    if (payload.links) {
      (true && (0, _debug.warn)(`You pushed a record of type '${type}' with a relationship '${field}' configured as 'async: false'. You've included a link but no primary data, this may be an error in your payload. EmberData will treat this relationship as known-to-be-empty.`, isAsync || !!payload.data || state.hasReceivedData, {
        id: 'ds.store.push-link-for-sync-relationship'
      }));
    } else if (payload.data) {
      if (kind === 'belongsTo') {
        (true && !(!Array.isArray(payload.data)) && (0, _debug.assert)(`A ${type} record was pushed into the store with the value of ${field} being ${(0, _debug.inspect)(payload.data)}, but ${field} is a belongsTo relationship so the value must not be an array. You should probably check your data payload or serializer.`, !Array.isArray(payload.data)));
        assertRelationshipData(getStore(graph.store), identifier, payload.data, definition);
      } else if (kind === 'hasMany') {
        (true && !(Array.isArray(payload.data)) && (0, _debug.assert)(`A ${type} record was pushed into the store with the value of ${field} being '${(0, _debug.inspect)(payload.data)}', but ${field} is a hasMany relationship so the value must be an array. You should probably check your data payload or serializer.`, Array.isArray(payload.data)));
        if (Array.isArray(payload.data)) {
          for (let i = 0; i < payload.data.length; i++) {
            assertRelationshipData(getStore(graph.store), identifier, payload.data[i], definition);
          }
        }
      }
    }
  }
  function isNew(identifier) {
    if (!identifier.id) {
      return true;
    }
    const cache = (0, _private.peekCache)(identifier);
    return Boolean(cache?.isNew(identifier));
  }
  function isBelongsTo(relationship) {
    return relationship.definition.kind === 'belongsTo';
  }
  function isImplicit(relationship) {
    return relationship.definition.isImplicit;
  }
  function isHasMany(relationship) {
    return relationship.definition.kind === 'hasMany';
  }
  function forAllRelatedIdentifiers(rel, cb) {
    if (isBelongsTo(rel)) {
      if (rel.remoteState) {
        cb(rel.remoteState);
      }
      if (rel.localState && rel.localState !== rel.remoteState) {
        cb(rel.localState);
      }
    } else if (isHasMany(rel)) {
      // TODO
      // rel.remoteMembers.forEach(cb);
      // might be simpler if performance is not a concern
      for (let i = 0; i < rel.remoteState.length; i++) {
        const inverseIdentifier = rel.remoteState[i];
        cb(inverseIdentifier);
      }
      rel.additions?.forEach(cb);
    } else {
      rel.localMembers.forEach(cb);
      rel.remoteMembers.forEach(inverseIdentifier => {
        if (!rel.localMembers.has(inverseIdentifier)) {
          cb(inverseIdentifier);
        }
      });
    }
  }

  /*
    Removes the given identifier from BOTH remote AND local state.
  
    This method is useful when either a deletion or a rollback on a new record
    needs to entirely purge itself from an inverse relationship.
    */
  function removeIdentifierCompletelyFromRelationship(graph, relationship, value, silenceNotifications) {
    if (isBelongsTo(relationship)) {
      if (relationship.remoteState === value) {
        relationship.remoteState = null;
      }
      if (relationship.localState === value) {
        relationship.localState = null;
        // This allows dematerialized inverses to be rematerialized
        // we shouldn't be notifying here though, figure out where
        // a notification was missed elsewhere.
        if (!silenceNotifications) {
          notifyChange(graph, relationship.identifier, relationship.definition.key);
        }
      }
    } else if (isHasMany(relationship)) {
      relationship.remoteMembers.delete(value);
      relationship.additions?.delete(value);
      const wasInRemovals = relationship.removals?.delete(value);
      const canonicalIndex = relationship.remoteState.indexOf(value);
      if (canonicalIndex !== -1) {
        relationship.remoteState.splice(canonicalIndex, 1);
      }
      if (!wasInRemovals) {
        const currentIndex = relationship.localState?.indexOf(value);
        if (currentIndex !== -1 && currentIndex !== undefined) {
          relationship.localState.splice(currentIndex, 1);
          // This allows dematerialized inverses to be rematerialized
          // we shouldn't be notifying here though, figure out where
          // a notification was missed elsewhere.
          if (!silenceNotifications) {
            notifyChange(graph, relationship.identifier, relationship.definition.key);
          }
        }
      }
    } else {
      relationship.remoteMembers.delete(value);
      relationship.localMembers.delete(value);
    }
  }

  // TODO add silencing at the graph level
  function notifyChange(graph, identifier, key) {
    if (identifier === graph._removing) {
      if ((0, _runtime.macroCondition)((0, _runtime.config)("/home/tj/crimeisdown-v3/node_modules/@ember-data/graph").debug.LOG_GRAPH)) {
        // eslint-disable-next-line no-console
        console.log(`Graph: ignoring relationship change for removed identifier ${String(identifier)} ${key}`);
      }
      return;
    }
    if ((0, _runtime.macroCondition)((0, _runtime.config)("/home/tj/crimeisdown-v3/node_modules/@ember-data/graph").debug.LOG_GRAPH)) {
      // eslint-disable-next-line no-console
      console.log(`Graph: notifying relationship change for ${String(identifier)} ${key}`);
    }
    graph.store.notifyChange(identifier, 'relationships', key);
  }
  function assertRelationshipData(store, identifier, data, meta) {
    (true && !(!Array.isArray(data)) && (0, _debug.assert)(`A ${identifier.type} record was pushed into the store with the value of ${meta.key} being '${JSON.stringify(data)}', but ${meta.key} is a belongsTo relationship so the value must not be an array. You should probably check your data payload or serializer.`, !Array.isArray(data)));
    (true && !(data === null || 'type' in data && typeof data.type === 'string' && data.type.length) && (0, _debug.assert)(`Encountered a relationship identifier without a type for the ${meta.kind} relationship '${meta.key}' on <${identifier.type}:${String(identifier.id)}>, expected an identifier with type '${meta.type}' but found\n\n'${JSON.stringify(data, null, 2)}'\n\nPlease check your serializer and make sure it is serializing the relationship payload into a JSON API format.`, data === null || 'type' in data && typeof data.type === 'string' && data.type.length));
    (true && !(data === null || !!coerceId(data.id)) && (0, _debug.assert)(`Encountered a relationship identifier without an id for the ${meta.kind} relationship '${meta.key}' on <${identifier.type}:${String(identifier.id)}>, expected an identifier but found\n\n'${JSON.stringify(data, null, 2)}'\n\nPlease check your serializer and make sure it is serializing the relationship payload into a JSON API format.`, data === null || !!coerceId(data.id)));
    if (data?.type === meta.type) {
      (true && !(store.getSchemaDefinitionService().doesTypeExist(data.type)) && (0, _debug.assert)(`Missing Schema: Encountered a relationship identifier { type: '${data.type}', id: '${String(data.id)}' } for the '${identifier.type}.${meta.key}' ${meta.kind} relationship on <${identifier.type}:${String(identifier.id)}>, but no schema exists for that type.`, store.getSchemaDefinitionService().doesTypeExist(data.type)));
    } else {
      (true && !(data === null || !data.type || store.getSchemaDefinitionService().doesTypeExist(data.type)) && (0, _debug.assert)(`Missing Schema: Encountered a relationship identifier with type '${data.type}' for the ${meta.kind} relationship '${meta.key}' on <${identifier.type}:${String(identifier.id)}>, Expected an identifier with type '${meta.type}'. No schema was found for '${data.type}'.`, data === null || !data.type || store.getSchemaDefinitionService().doesTypeExist(data.type)));
    }
  }

  /*
    Assert that `addedRecord` has a valid type so it can be added to the
    relationship of the `record`.
  
    The assert basically checks if the `addedRecord` can be added to the
    relationship (specified via `relationshipMeta`) of the `record`.
  
    This utility should only be used internally, as both record parameters must
    be stable record identifiers and the `relationshipMeta` needs to be the meta
    information about the relationship, retrieved via
    `record.relationshipFor(key)`.
  */
  let assertPolymorphicType;
  let assertInheritedSchema;
  if ((0, _runtime.macroCondition)((0, _runtime.config)("/home/tj/crimeisdown-v3/node_modules/@ember-data/graph").env.DEBUG)) {
    function validateSchema(definition, meta) {
      const errors = new Map();
      if (definition.inverseKey !== meta.name) {
        errors.set('name', ` <---- should be '${definition.inverseKey}'`);
      }
      if (definition.inverseType !== meta.type) {
        errors.set('type', ` <---- should be '${definition.inverseType}'`);
      }
      if (definition.inverseKind !== meta.kind) {
        errors.set('type', ` <---- should be '${definition.inverseKind}'`);
      }
      if (definition.inverseIsAsync !== meta.options.async) {
        errors.set('async', ` <---- should be ${definition.inverseIsAsync}`);
      }
      if (definition.inverseIsPolymorphic && definition.inverseIsPolymorphic !== meta.options.polymorphic) {
        errors.set('polymorphic', ` <---- should be ${definition.inverseIsPolymorphic}`);
      }
      if (definition.key !== meta.options.inverse) {
        errors.set('inverse', ` <---- should be '${definition.key}'`);
      }
      if (definition.type !== meta.options.as) {
        errors.set('as', ` <---- should be '${definition.type}'`);
      }
      return errors;
    }
    function expectedSchema(definition) {
      return printSchema({
        name: definition.inverseKey,
        type: definition.inverseType,
        kind: definition.inverseKind,
        options: {
          as: definition.type,
          async: definition.inverseIsAsync,
          polymorphic: definition.inverseIsPolymorphic || false,
          inverse: definition.key
        }
      });
    }
    function printSchema(config, errors) {
      return `

\`\`\`
{
  ${config.name}: {
    name: '${config.name}',${errors?.get('name') || ''}
    type: '${config.type}',${errors?.get('type') || ''}
    kind: '${config.kind}',${errors?.get('kind') || ''}
    options: {
      as: '${config.options.as}',${errors?.get('as') || ''}
      async: ${config.options.async},${errors?.get('async') || ''}
      polymorphic: ${config.options.polymorphic},${errors?.get('polymorphic') || ''}
      inverse: '${config.options.inverse}'${errors?.get('inverse') || ''}
    }
  }
}
\`\`\`

`;
    }
    function metaFrom(definition) {
      return {
        name: definition.key,
        type: definition.type,
        kind: definition.kind,
        options: {
          async: definition.isAsync,
          polymorphic: definition.isPolymorphic,
          inverse: definition.inverseKey
        }
      };
    }
    function inverseMetaFrom(definition) {
      return {
        name: definition.inverseKey,
        type: definition.inverseType,
        kind: definition.inverseKind,
        options: {
          as: definition.isPolymorphic ? definition.type : undefined,
          async: definition.inverseIsAsync,
          polymorphic: definition.inverseIsPolymorphic,
          inverse: definition.key
        }
      };
    }
    function inverseDefinition(definition) {
      return {
        key: definition.inverseKey,
        type: definition.inverseType,
        kind: definition.inverseKind,
        isAsync: definition.inverseIsAsync,
        isPolymorphic: true,
        isCollection: definition.inverseIsCollection,
        isImplicit: definition.inverseIsImplicit,
        inverseKey: definition.key,
        inverseType: definition.type,
        inverseKind: definition.kind,
        inverseIsAsync: definition.isAsync,
        inverseIsPolymorphic: definition.isPolymorphic,
        inverseIsImplicit: definition.isImplicit,
        inverseIsCollection: definition.isCollection,
        resetOnRemoteUpdate: definition.resetOnRemoteUpdate
      };
    }
    function definitionWithPolymorphic(definition) {
      return Object.assign({}, definition, {
        inverseIsPolymorphic: true
      });
    }
    assertInheritedSchema = function assertInheritedSchema(definition, type) {
      let meta1 = metaFrom(definition);
      let meta2 = inverseMetaFrom(definition);
      let errors1 = validateSchema(inverseDefinition(definition), meta1);
      let errors2 = validateSchema(definitionWithPolymorphic(definition), meta2);
      if (errors2.size === 0 && errors1.size > 0) {
        throw new Error(`The schema for the relationship '${type}.${definition.key}' is not configured to satisfy '${definition.inverseType}' and thus cannot utilize the '${definition.inverseType}.${definition.key}' relationship to connect with '${definition.type}.${definition.inverseKey}'\n\nIf using this relationship in a polymorphic manner is desired, the relationships schema definition for '${type}' should include:${printSchema(meta1, errors1)}`);
      } else if (errors1.size > 0) {
        throw new Error(`The schema for the relationship '${type}.${definition.key}' is not configured to satisfy '${definition.inverseType}' and thus cannot utilize the '${definition.inverseType}.${definition.key}' relationship to connect with '${definition.type}.${definition.inverseKey}'\n\nIf using this relationship in a polymorphic manner is desired, the relationships schema definition for '${type}' should include:${printSchema(meta1, errors1)} and the relationships schema definition for '${definition.type}' should include:${printSchema(meta2, errors2)}`);
      } else if (errors2.size > 0) {
        throw new Error(`The schema for the relationship '${type}.${definition.key}' satisfies '${definition.inverseType}' but cannot utilize the '${definition.inverseType}.${definition.key}' relationship to connect with '${definition.type}.${definition.inverseKey}' because that relationship is not polymorphic.\n\nIf using this relationship in a polymorphic manner is desired, the relationships schema definition for '${definition.type}' should include:${printSchema(meta2, errors2)}`);
      }
    };
    assertPolymorphicType = function assertPolymorphicType(parentIdentifier, parentDefinition, addedIdentifier, store) {
      if (parentDefinition.inverseIsImplicit) {
        return;
      }
      if (parentDefinition.isPolymorphic) {
        let meta = store.getSchemaDefinitionService().relationshipsDefinitionFor(addedIdentifier)[parentDefinition.inverseKey];
        (true && !(meta) && (0, _debug.assert)(`No '${parentDefinition.inverseKey}' field exists on '${addedIdentifier.type}'. To use this type in the polymorphic relationship '${parentDefinition.inverseType}.${parentDefinition.key}' the relationships schema definition for ${addedIdentifier.type} should include:${expectedSchema(parentDefinition)}`, meta));
        (true && !(!(meta.options.inverse === null && meta?.options.as?.length)) && (0, _debug.assert)(`You should not specify both options.as and options.inverse as null on ${addedIdentifier.type}.${parentDefinition.inverseKey}, as if there is no inverse field there is no abstract type to conform to. You may have intended for this relationship to be polymorphic, or you may have mistakenly set inverse to null.`, !(meta.options.inverse === null && meta?.options.as?.length)));
        let errors = validateSchema(parentDefinition, meta);
        (true && !(errors.size === 0) && (0, _debug.assert)(`The schema for the relationship '${parentDefinition.inverseKey}' on '${addedIdentifier.type}' type does not correctly implement '${parentDefinition.type}' and thus cannot be assigned to the '${parentDefinition.key}' relationship in '${parentIdentifier.type}'. If using this record in this polymorphic relationship is desired, correct the errors in the schema shown below:${printSchema(meta, errors)}`, errors.size === 0));
      } else if (addedIdentifier.type !== parentDefinition.type) {
        // if we are not polymorphic
        // then the addedIdentifier.type must be the same as the parentDefinition.type
        let meta = store.getSchemaDefinitionService().relationshipsDefinitionFor(addedIdentifier)[parentDefinition.inverseKey];
        if (meta?.options.as === parentDefinition.type) {
          // inverse is likely polymorphic but missing the polymorphic flag
          let meta = store.getSchemaDefinitionService().relationshipsDefinitionFor({
            type: parentDefinition.inverseType
          })[parentDefinition.key];
          let errors = validateSchema(definitionWithPolymorphic(inverseDefinition(parentDefinition)), meta);
          (true && !(false) && (0, _debug.assert)(`The '<${addedIdentifier.type}>.${parentDefinition.inverseKey}' relationship cannot be used polymorphically because '<${parentDefinition.inverseType}>.${parentDefinition.key} is not a polymorphic relationship. To use this relationship in a polymorphic manner, fix the following schema issues on the relationships schema for '${parentDefinition.inverseType}':${printSchema(meta, errors)}`));
        } else {
          (true && !(false) && (0, _debug.assert)(`The '${addedIdentifier.type}' type does not implement '${parentDefinition.type}' and thus cannot be assigned to the '${parentDefinition.key}' relationship in '${parentIdentifier.type}'. If this relationship should be polymorphic, mark ${parentDefinition.inverseType}.${parentDefinition.key} as \`polymorphic: true\` and ${addedIdentifier.type}.${parentDefinition.inverseKey} as implementing it via \`as: '${parentDefinition.type}'\`.`));
        }
      }
    };
  }

  /*
      case many:1
      ========
      In a bi-directional graph with Many:1 edges, adding a value
      results in up-to 3 discrete value transitions, while removing
      a value is only 2 transitions.
  
      For adding C to A
      If: A <<-> B, C <->> D is the initial state,
      and: B <->> A <<-> C, D is the final state
  
      then we would undergo the following transitions.
  
      add C to A
      remove C from D
      add A to C
  
      For removing B from A
      If: A <<-> B, C <->> D is the initial state,
      and: A, B, C <->> D is the final state
  
      then we would undergo the following transitions.
  
      remove B from A
      remove A from B
  
      case many:many
      ===========
      In a bi-directional graph with Many:Many edges, adding or
      removing a value requires only 2 value transitions.
  
      For Adding
      If: A<<->>B, C<<->>D is the initial state (double arrows representing the many side)
      And: D<<->>C<<->>A<<->>B is the final state
  
      Then we would undergo two transitions.
  
      add C to A.
      add A to C
  
      For Removing
      If: A<<->>B, C<<->>D is the initial state (double arrows representing the many side)
      And: A, B, C<<->>D is the final state
  
      Then we would undergo two transitions.
  
      remove B from A
      remove A from B
  
      case many:?
      ========
      In a uni-directional graph with Many:? edges (modeled in EmberData with `inverse:null`) with
      artificial (implicit) inverses, replacing a value results in 2 discrete value transitions.
      This is because a Many:? relationship is effectively Many:Many.
    */
  function replaceRelatedRecords(graph, op, isRemote) {
    if (isRemote) {
      replaceRelatedRecordsRemote(graph, op, isRemote);
    } else {
      replaceRelatedRecordsLocal(graph, op, isRemote);
    }
  }
  function replaceRelatedRecordsLocal(graph, op, isRemote) {
    const identifiers = op.value;
    const relationship = graph.get(op.record, op.field);
    (true && !(isHasMany(relationship)) && (0, _debug.assert)(`expected hasMany relationship`, isHasMany(relationship)));
    relationship.state.hasReceivedData = true;
    const {
      additions,
      removals
    } = relationship;
    const {
      inverseKey,
      type
    } = relationship.definition;
    const {
      record
    } = op;
    const wasDirty = relationship.isDirty;
    relationship.isDirty = false;
    const onAdd = identifier => {
      // Since we are diffing against the remote state, we check
      // if our previous local state did not contain this identifier
      const removalsHas = removals?.has(identifier);
      if (removalsHas || !additions?.has(identifier)) {
        if (type !== identifier.type) {
          if ((0, _runtime.macroCondition)((0, _runtime.config)("/home/tj/crimeisdown-v3/node_modules/@ember-data/graph").env.DEBUG)) {
            assertPolymorphicType(relationship.identifier, relationship.definition, identifier, graph.store);
          }
          graph.registerPolymorphicType(type, identifier.type);
        }
        relationship.isDirty = true;
        addToInverse(graph, identifier, inverseKey, op.record, isRemote);
        if (removalsHas) {
          removals.delete(identifier);
        }
      }
    };
    const onRemove = identifier => {
      // Since we are diffing against the remote state, we check
      // if our previous local state had contained this identifier
      const additionsHas = additions?.has(identifier);
      if (additionsHas || !removals?.has(identifier)) {
        relationship.isDirty = true;
        removeFromInverse(graph, identifier, inverseKey, record, isRemote);
        if (additionsHas) {
          additions.delete(identifier);
        }
      }
    };
    const diff = diffCollection(identifiers, relationship, onAdd, onRemove);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    relationship.isDirty || diff.changed;

    // any additions no longer in the local state
    // need to be removed from the inverse
    if (additions && additions.size > 0) {
      additions.forEach(identifier => {
        if (!diff.add.has(identifier)) {
          onRemove(identifier);
        }
      });
    }

    // any removals no longer in the local state
    // need to be added back to the inverse
    if (removals && removals.size > 0) {
      removals.forEach(identifier => {
        if (!diff.del.has(identifier)) {
          onAdd(identifier);
        }
      });
    }
    relationship.additions = diff.add;
    relationship.removals = diff.del;
    relationship.localState = diff.finalState;
    relationship.isDirty = wasDirty;
    if (!wasDirty /*&& becameDirty // TODO to guard like this we need to detect reorder when diffing local */) {
      notifyChange(graph, op.record, op.field);
    }
  }
  function replaceRelatedRecordsRemote(graph, op, isRemote) {
    const identifiers = op.value;
    const relationship = graph.get(op.record, op.field);
    (true && !(isHasMany(relationship)) && (0, _debug.assert)(`You can only '${op.op}' on a hasMany relationship. ${op.record.type}.${op.field} is a ${relationship.definition.kind}`, isHasMany(relationship)));
    if (isRemote) {
      graph._addToTransaction(relationship);
    }
    relationship.state.hasReceivedData = true;

    // cache existing state
    const {
      definition
    } = relationship;
    const {
      type
    } = relationship.definition;
    const diff = diffCollection(identifiers, relationship, identifier => {
      if (type !== identifier.type) {
        if ((0, _runtime.macroCondition)((0, _runtime.config)("/home/tj/crimeisdown-v3/node_modules/@ember-data/graph").env.DEBUG)) {
          assertPolymorphicType(relationship.identifier, relationship.definition, identifier, graph.store);
        }
        graph.registerPolymorphicType(type, identifier.type);
      }
      // commit additions
      // TODO build this into the diff?
      // because we are not dirty if this was a committed local addition
      if (relationship.additions?.has(identifier)) {
        relationship.additions.delete(identifier);
      } else {
        relationship.isDirty = true;
      }
      addToInverse(graph, identifier, definition.inverseKey, op.record, isRemote);
    }, identifier => {
      // commit removals
      // TODO build this into the diff?
      // because we are not dirty if this was a committed local addition
      if (relationship.removals?.has(identifier)) {
        relationship.removals.delete(identifier);
      } else {
        relationship.isDirty = true;
      }
      removeFromInverse(graph, identifier, definition.inverseKey, op.record, isRemote);
    });

    // replace existing state
    relationship.remoteMembers = diff.finalSet;
    relationship.remoteState = diff.finalState;

    // changed also indicates a change in order
    if (diff.changed) {
      relationship.isDirty = true;
    }

    // TODO unsure if we need this but it
    // may allow us to more efficiently patch
    // the associated ManyArray
    relationship._diff = diff;
    if ((0, _runtime.macroCondition)((0, _runtime.config)("/home/tj/crimeisdown-v3/node_modules/@ember-data/graph").deprecations.DEPRECATE_RELATIONSHIP_REMOTE_UPDATE_CLEARING_LOCAL_STATE)) {
      // only do this for legacy hasMany, not collection
      // and provide a way to incrementally migrate
      if (relationship.definition.kind === 'hasMany' && relationship.definition.resetOnRemoteUpdate !== false) {
        const deprecationInfo = {
          removals: [],
          additions: [],
          triggered: false
        };
        if (relationship.removals) {
          relationship.isDirty = true;
          relationship.removals.forEach(identifier => {
            deprecationInfo.triggered = true;
            deprecationInfo.removals.push(identifier);
            // reverse the removal
            // if we are still in removals at this point then
            // we were not "committed" which means we are present
            // in the remoteMembers. So we "add back" on the inverse.
            addToInverse(graph, identifier, definition.inverseKey, op.record, isRemote);
          });
          relationship.removals = null;
        }
        if (relationship.additions) {
          relationship.additions.forEach(identifier => {
            // reverse the addition
            // if we are still in additions at this point then
            // we were not "committed" which means we are not present
            // in the remoteMembers. So we "remove" from the inverse.
            // however we only do this if we are not a "new" record.
            if (!isNew(identifier)) {
              deprecationInfo.triggered = true;
              deprecationInfo.additions.push(identifier);
              relationship.isDirty = true;
              relationship.additions.delete(identifier);
              removeFromInverse(graph, identifier, definition.inverseKey, op.record, isRemote);
            }
          });
          if (relationship.additions.size === 0) {
            relationship.additions = null;
          }
        }
        if (deprecationInfo.triggered) {
          (true && !(false) && (0, _debug.deprecate)(`EmberData is changing the default semantics of updates to the remote state of relationships.\n\nThe following local state was cleared from the <${relationship.identifier.type}>.${relationship.definition.key} hasMany relationship but will not be once this deprecation is resolved by opting into the new behavior:\n\n\tAdded: [${deprecationInfo.additions.map(i => i.lid).join(', ')}]\n\tRemoved: [${deprecationInfo.removals.map(i => i.lid).join(', ')}]`, false, {
            id: 'ember-data:deprecate-relationship-remote-update-clearing-local-state',
            for: 'ember-data',
            since: {
              enabled: '5.3',
              available: '5.3'
            },
            until: '6.0',
            url: 'https://deprecations.emberjs.com/v5.x#ember-data-deprecate-relationship-remote-update-clearing-local-state'
          }));
        }
      }
    }
    if (relationship.isDirty) {
      flushCanonical(graph, relationship);
    }
  }
  function addToInverse(graph, identifier, key, value, isRemote) {
    const relationship = graph.get(identifier, key);
    const {
      type
    } = relationship.definition;
    if (type !== value.type) {
      if ((0, _runtime.macroCondition)((0, _runtime.config)("/home/tj/crimeisdown-v3/node_modules/@ember-data/graph").env.DEBUG)) {
        assertPolymorphicType(relationship.identifier, relationship.definition, value, graph.store);
      }
      graph.registerPolymorphicType(type, value.type);
    }
    if (isBelongsTo(relationship)) {
      relationship.state.hasReceivedData = true;
      relationship.state.isEmpty = false;
      if (isRemote) {
        graph._addToTransaction(relationship);
        if (relationship.remoteState !== null) {
          removeFromInverse(graph, relationship.remoteState, relationship.definition.inverseKey, identifier, isRemote);
        }
        relationship.remoteState = value;
      }
      if (relationship.localState !== value) {
        if (!isRemote && relationship.localState) {
          removeFromInverse(graph, relationship.localState, relationship.definition.inverseKey, identifier, isRemote);
        }
        relationship.localState = value;
        notifyChange(graph, identifier, key);
      }
    } else if (isHasMany(relationship)) {
      if (isRemote) {
        // TODO this needs to alert stuffs
        // And patch state better
        // This is almost definitely wrong
        // WARNING WARNING WARNING

        if (!relationship.remoteMembers.has(value)) {
          graph._addToTransaction(relationship);
          relationship.remoteState.push(value);
          relationship.remoteMembers.add(value);
          if (relationship.additions?.has(value)) {
            relationship.additions.delete(value);
          } else {
            relationship.isDirty = true;
            relationship.state.hasReceivedData = true;
            flushCanonical(graph, relationship);
          }
        }
      } else {
        if (_addLocal(graph, identifier, relationship, value, null)) {
          notifyChange(graph, identifier, key);
        }
      }
    } else {
      if (isRemote) {
        if (!relationship.remoteMembers.has(value)) {
          relationship.remoteMembers.add(value);
          relationship.localMembers.add(value);
        }
      } else {
        if (!relationship.localMembers.has(value)) {
          relationship.localMembers.add(value);
        }
      }
    }
  }
  function notifyInverseOfPotentialMaterialization(graph, identifier, key, value, isRemote) {
    const relationship = graph.get(identifier, key);
    if (isHasMany(relationship) && isRemote && relationship.remoteMembers.has(value)) {
      notifyChange(graph, identifier, key);
    }
  }
  function removeFromInverse(graph, identifier, key, value, isRemote) {
    const relationship = graph.get(identifier, key);
    if (isBelongsTo(relationship)) {
      relationship.state.isEmpty = true;
      if (isRemote) {
        graph._addToTransaction(relationship);
        relationship.remoteState = null;
      }
      if (relationship.localState === value) {
        relationship.localState = null;
        notifyChange(graph, identifier, key);
      }
    } else if (isHasMany(relationship)) {
      if (isRemote) {
        graph._addToTransaction(relationship);
        if (_removeRemote(relationship, value)) {
          notifyChange(graph, identifier, key);
        }
      } else {
        if (_removeLocal(relationship, value)) {
          notifyChange(graph, identifier, key);
        }
      }
    } else {
      if (isRemote) {
        relationship.remoteMembers.delete(value);
        relationship.localMembers.delete(value);
      } else {
        if (value && relationship.localMembers.has(value)) {
          relationship.localMembers.delete(value);
        }
      }
    }
  }
  function flushCanonical(graph, rel) {
    graph._scheduleLocalSync(rel);
  }
  function replaceRelatedRecord(graph, op, isRemote = false) {
    const relationship = graph.get(op.record, op.field);
    (true && !(isBelongsTo(relationship)) && (0, _debug.assert)(`You can only '${op.op}' on a belongsTo relationship. ${op.record.type}.${op.field} is a ${relationship.definition.kind}`, isBelongsTo(relationship)));
    if (isRemote) {
      graph._addToTransaction(relationship);
    }
    const {
      definition,
      state
    } = relationship;
    const prop = isRemote ? 'remoteState' : 'localState';
    const existingState = relationship[prop];

    /*
      case 1:1
      ========
      In a bi-directional graph with 1:1 edges, replacing a value
      results in up-to 4 discrete value transitions.
       If: A <-> B, C <-> D is the initial state,
      and: A <-> C, B, D is the final state
       then we would undergo the following 4 transitions.
       remove A from B
      add C to A
      remove C from D
      add A to C
       case 1:many
      ===========
      In a bi-directional graph with 1:Many edges, replacing a value
      results in up-to 3 discrete value transitions.
       If: A<->>B<<->D, C<<->D is the initial state (double arrows representing the many side)
      And: A<->>C<<->D, B<<->D is the final state
       Then we would undergo three transitions.
       remove A from B
      add C to A.
      add A to C
       case 1:?
      ========
      In a uni-directional graph with 1:? edges (modeled in EmberData with `inverse:null`) with
      artificial (implicit) inverses, replacing a value results in up-to 3 discrete value transitions.
      This is because a 1:? relationship is effectively 1:many.
       If: A->B, C->B is the initial state
      And: A->C, C->B is the final state
       Then we would undergo three transitions.
       Remove A from B
      Add C to A
      Add A to C
    */

    // nothing for us to do
    if (op.value === existingState) {
      // if we were empty before but now know we are empty this needs to be true
      state.hasReceivedData = true;
      // if this is a remote update we still sync
      if (isRemote) {
        const {
          localState
        } = relationship;
        // don't sync if localState is a new record and our remoteState is null
        if (localState && isNew(localState) && !existingState) {
          return;
        }
        if (existingState && localState === existingState) {
          notifyInverseOfPotentialMaterialization(graph, existingState, definition.inverseKey, op.record, isRemote);
        } else if ((0, _runtime.macroCondition)((0, _runtime.config)("/home/tj/crimeisdown-v3/node_modules/@ember-data/graph").deprecations.DEPRECATE_RELATIONSHIP_REMOTE_UPDATE_CLEARING_LOCAL_STATE)) {
          // if localState does not match existingState then we know
          // we have a local mutation that has not been persisted yet
          if (localState !== op.value && relationship.definition.resetOnRemoteUpdate !== false) {
            relationship.localState = existingState;
            (true && !(false) && (0, _debug.deprecate)(`EmberData is changing the default semantics of updates to the remote state of relationships.\n\nThe following local state was cleared from the <${relationship.identifier.type}>.${relationship.definition.key} belongsTo relationship but will not be once this deprecation is resolved:\n\n\t${localState ? 'Added: ' + localState.lid + '\n\t' : ''}${existingState ? 'Removed: ' + existingState.lid : ''}`, false, {
              id: 'ember-data:deprecate-relationship-remote-update-clearing-local-state',
              for: 'ember-data',
              since: {
                enabled: '5.3',
                available: '5.3'
              },
              until: '6.0',
              url: 'https://deprecations.emberjs.com/v5.x#ember-data-deprecate-relationship-remote-update-clearing-local-state'
            }));
            notifyChange(graph, relationship.identifier, relationship.definition.key);
          }
        }
      }
      return;
    }

    // remove this value from the inverse if required
    if (existingState) {
      removeFromInverse(graph, existingState, definition.inverseKey, op.record, isRemote);
    }

    // update value to the new value
    relationship[prop] = op.value;
    state.hasReceivedData = true;
    state.isEmpty = op.value === null;
    state.isStale = false;
    state.hasFailedLoadAttempt = false;
    if (op.value) {
      if (definition.type !== op.value.type) {
        // assert(
        //   `The '<${definition.inverseType}>.${op.field}' relationship expects only '${definition.type}' records since it is not polymorphic. Received a Record of type '${op.value.type}'`,
        //   definition.isPolymorphic
        // );

        // TODO this should now handle the deprecation warning if isPolymorphic is not set
        // but the record does turn out to be polymorphic
        // this should still assert if the user is relying on legacy inheritance/mixins to
        // provide polymorphic behavior and has not yet added the polymorphic flags
        if ((0, _runtime.macroCondition)((0, _runtime.config)("/home/tj/crimeisdown-v3/node_modules/@ember-data/graph").env.DEBUG)) {
          assertPolymorphicType(relationship.identifier, definition, op.value, graph.store);
        }
        graph.registerPolymorphicType(definition.type, op.value.type);
      }
      addToInverse(graph, op.value, definition.inverseKey, op.record, isRemote);
    }
    if (isRemote) {
      const {
        localState,
        remoteState
      } = relationship;
      if (localState && isNew(localState) && !remoteState) {
        return;
      }
      // when localState does not match the new remoteState and
      // localState === existingState then we had no local mutation
      // and we can safely sync the new remoteState to local
      if (localState !== remoteState && localState === existingState) {
        relationship.localState = remoteState;
        notifyChange(graph, relationship.identifier, relationship.definition.key);
        // But when localState does not match the new remoteState and
        // and localState !== existingState then we know we have a local mutation
        // that has not been persisted yet.
      } else if ((0, _runtime.macroCondition)((0, _runtime.config)("/home/tj/crimeisdown-v3/node_modules/@ember-data/graph").deprecations.DEPRECATE_RELATIONSHIP_REMOTE_UPDATE_CLEARING_LOCAL_STATE)) {
        if (localState !== remoteState && localState !== existingState && relationship.definition.resetOnRemoteUpdate !== false) {
          relationship.localState = existingState;
          (true && !(false) && (0, _debug.deprecate)(`EmberData is changing the default semantics of updates to the remote state of relationships.\n\nThe following local state was cleared from the <${relationship.identifier.type}>.${relationship.definition.key} belongsTo relationship but will not be once this deprecation is resolved:\n\n\t${localState ? 'Added: ' + localState.lid + '\n\t' : ''}${existingState ? 'Removed: ' + existingState.lid : ''}`, false, {
            id: 'ember-data:deprecate-relationship-remote-update-clearing-local-state',
            for: 'ember-data',
            since: {
              enabled: '5.3',
              available: '5.3'
            },
            until: '6.0',
            url: 'https://deprecations.emberjs.com/v5.x#ember-data-deprecate-relationship-remote-update-clearing-local-state'
          }));
          notifyChange(graph, relationship.identifier, relationship.definition.key);
        }
      }
    } else {
      notifyChange(graph, relationship.identifier, relationship.definition.key);
    }
  }
  function _deprecatedCompare(newState, newMembers, prevState, prevSet, onAdd, onDel) {
    const newLength = newState.length;
    const prevLength = prevState.length;
    const iterationLength = Math.max(newLength, prevLength);
    let changed = newMembers.size !== prevSet.size;
    const added = new Set();
    const removed = new Set();
    const duplicates = new Map();
    const finalSet = new Set();
    const finalState = [];
    for (let i = 0, j = 0; i < iterationLength; i++) {
      let adv = false;
      let member;

      // accumulate anything added
      if (i < newLength) {
        member = newState[i];
        if (!finalSet.has(member)) {
          finalState[j] = member;
          finalSet.add(member);
          adv = true;
          if (!prevSet.has(member)) {
            changed = true;
            added.add(member);
            onAdd(member);
          }
        } else {
          let list = duplicates.get(member);
          if (list === undefined) {
            list = [];
            duplicates.set(member, list);
          }
          list.push(i);
        }
      }

      // accumulate anything removed
      if (i < prevLength) {
        const prevMember = prevState[i];

        // detect reordering, adjusting index for duplicates
        // j is always less than i and so if i < prevLength, j < prevLength
        if (member !== prevState[j]) {
          changed = true;
        }
        if (!newMembers.has(prevMember)) {
          changed = true;
          removed.add(prevMember);
          onDel(prevMember);
        }
      } else if (adv && j < prevLength && member !== prevState[j]) {
        changed = true;
      }
      if (adv) {
        j++;
      }
    }
    const diff = {
      add: added,
      del: removed,
      finalState,
      finalSet,
      changed
    };
    return {
      diff,
      duplicates
    };
  }
  function _compare(finalState, finalSet, prevState, prevSet, onAdd, onDel) {
    const finalLength = finalState.length;
    const prevLength = prevState.length;
    const iterationLength = Math.max(finalLength, prevLength);
    const equalLength = finalLength === prevLength;
    let changed = finalSet.size !== prevSet.size;
    const added = new Set();
    const removed = new Set();
    for (let i = 0; i < iterationLength; i++) {
      let member;

      // accumulate anything added
      if (i < finalLength) {
        member = finalState[i];
        if (!prevSet.has(member)) {
          changed = true;
          added.add(member);
          onAdd(member);
        }
      }

      // accumulate anything removed
      if (i < prevLength) {
        const prevMember = prevState[i];

        // detect reordering
        if (equalLength && member !== prevMember) {
          changed = true;
        }
        if (!finalSet.has(prevMember)) {
          changed = true;
          removed.add(prevMember);
          onDel(prevMember);
        }
      }
    }
    return {
      add: added,
      del: removed,
      finalState,
      finalSet,
      changed
    };
  }
  function diffCollection(finalState, relationship, onAdd, onDel) {
    const finalSet = new Set(finalState);
    const {
      remoteState,
      remoteMembers
    } = relationship;
    if ((0, _runtime.macroCondition)((0, _runtime.config)("/home/tj/crimeisdown-v3/node_modules/@ember-data/graph").deprecations.DEPRECATE_NON_UNIQUE_PAYLOADS)) {
      if (finalState.length !== finalSet.size) {
        const {
          diff,
          duplicates
        } = _deprecatedCompare(finalState, finalSet, remoteState, remoteMembers, onAdd, onDel);
        if ((0, _runtime.macroCondition)((0, _runtime.config)("/home/tj/crimeisdown-v3/node_modules/@ember-data/graph").env.DEBUG)) {
          (true && !(false) && (0, _debug.deprecate)(`Expected all entries in the relationship ${relationship.definition.type}:${relationship.definition.key} to be unique, see log for a list of duplicate entry indeces`, false, {
            id: 'ember-data:deprecate-non-unique-relationship-entries',
            for: 'ember-data',
            until: '6.0',
            since: {
              available: '5.3',
              enabled: '5.3'
            }
          })); // eslint-disable-next-line no-console
          console.log(duplicates);
        }
        return diff;
      }
    } else {
      (true && !(finalState.length === finalSet.size) && (0, _debug.assert)(`Expected all entries in the relationship to be unique, found duplicates`, finalState.length === finalSet.size));
    }
    return _compare(finalState, finalSet, remoteState, remoteMembers, onAdd, onDel);
  }
  function computeLocalState(storage) {
    if (!storage.isDirty) {
      (true && !(Array.isArray(storage.localState)) && (0, _debug.assert)(`Expected localState to be present`, Array.isArray(storage.localState)));
      return storage.localState;
    }
    const state = storage.remoteState.slice();
    storage.removals?.forEach(v => {
      const index = state.indexOf(v);
      state.splice(index, 1);
    });
    storage.additions?.forEach(v => {
      state.push(v);
    });
    storage.localState = state;
    storage.isDirty = false;
    return state;
  }
  function _addLocal(graph, record, relationship, value, index) {
    const {
      remoteMembers,
      removals
    } = relationship;
    let additions = relationship.additions;
    const hasPresence = remoteMembers.has(value) || additions?.has(value);
    if (hasPresence && !removals?.has(value)) {
      (true && !(hasPresence && !removals?.has(value)) && (0, _debug.assert)(`Attempted to add the resource '${value.lid}' to the collection <${relationship.identifier.type}>.${relationship.definition.key} it was already in`, hasPresence && !removals?.has(value)));
      return false;
    }
    if (removals?.has(value)) {
      removals.delete(value);
    } else {
      if (!additions) {
        additions = relationship.additions = new Set();
      }
      relationship.state.hasReceivedData = true;
      additions.add(value);
      const {
        type
      } = relationship.definition;
      if (type !== value.type) {
        if ((0, _runtime.macroCondition)((0, _runtime.config)("/home/tj/crimeisdown-v3/node_modules/@ember-data/graph").env.DEBUG)) {
          assertPolymorphicType(record, relationship.definition, value, graph.store);
        }
        graph.registerPolymorphicType(value.type, type);
      }
    }

    // if we have existing localState
    // and we have an index
    // apply the change, as this is more efficient
    // than recomputing localState and
    // it allows us to preserve local ordering
    // to a small extend. Local ordering should not
    // be relied upon as any remote change will blow it away
    if (relationship.localState) {
      if (index !== null) {
        relationship.localState.splice(index, 0, value);
      } else {
        relationship.localState.push(value);
      }
    }
    (true && !(relationship.localState || relationship.isDirty) && (0, _debug.assert)(`Expected relationship to be dirty when adding a local mutation`, relationship.localState || relationship.isDirty));
    return true;
  }
  function _removeLocal(relationship, value) {
    (true && !(value) && (0, _debug.assert)(`expected an identifier to remove from the collection relationship`, value));
    const {
      remoteMembers,
      additions
    } = relationship;
    let removals = relationship.removals;
    const hasPresence = remoteMembers.has(value) || additions?.has(value);
    if (!hasPresence || removals?.has(value)) {
      (true && !(!hasPresence || removals?.has(value)) && (0, _debug.assert)(`Attempted to remove the resource '${value.lid}' from the collection <${relationship.identifier.type}>.${relationship.definition.key} but it was not present`, !hasPresence || removals?.has(value)));
      return false;
    }
    if (additions?.has(value)) {
      additions.delete(value);
    } else {
      if (!removals) {
        removals = relationship.removals = new Set();
      }
      removals.add(value);
    }

    // if we have existing localState
    // apply the change, as this is more efficient
    // than recomputing localState and
    // it allows us to preserve local ordering
    // to a small extend. Local ordering should not
    // be relied upon as any remote change will blow it away
    if (relationship.localState) {
      const index = relationship.localState.indexOf(value);
      (true && !(index !== -1) && (0, _debug.assert)(`Cannot remove a resource that is not present`, index !== -1));
      relationship.localState.splice(index, 1);
    }
    (true && !(relationship.localState || relationship.isDirty) && (0, _debug.assert)(`Expected relationship to be dirty when performing a local mutation`, relationship.localState || relationship.isDirty));
    return true;
  }
  function _removeRemote(relationship, value) {
    (true && !(value) && (0, _debug.assert)(`expected an identifier to remove from the collection relationship`, value));
    const {
      remoteMembers,
      additions,
      removals,
      remoteState
    } = relationship;
    (true && !(remoteMembers.has(value)) && (0, _debug.assert)(`Cannot remove a resource that is not present`, remoteMembers.has(value)));
    if (!remoteMembers.has(value)) {
      return false;
    }

    // remove from remote state
    remoteMembers.delete(value);
    let index = remoteState.indexOf(value);
    (true && !(index !== -1) && (0, _debug.assert)(`Cannot remove a resource that is not present`, index !== -1));
    remoteState.splice(index, 1);

    // remove from removals if present
    if (removals?.has(value)) {
      removals.delete(value);

      // nothing more to do this was our state already
      return false;
    }
    (true && !(!additions?.has(value)) && (0, _debug.assert)(`Remote state indicated removal of a resource that was present only as a local mutation`, !additions?.has(value))); // if we have existing localState
    // and we have an index
    // apply the change, as this is more efficient
    // than recomputing localState and
    // it allows us to preserve local ordering
    // to a small extend. Local ordering should not
    // be relied upon as any remote change will blow it away
    if (relationship.localState) {
      index = relationship.localState.indexOf(value);
      (true && !(index !== -1) && (0, _debug.assert)(`Cannot remove a resource that is not present`, index !== -1));
      relationship.localState.splice(index, 1);
    }
    (true && !(relationship.localState || relationship.isDirty) && (0, _debug.assert)(`Expected relationship to be dirty when performing a local mutation`, relationship.localState || relationship.isDirty));
    return true;
  }
  function rollbackRelationship(graph, identifier, field, relationship) {
    if (isBelongsTo(relationship)) {
      replaceRelatedRecord(graph, {
        op: 'replaceRelatedRecord',
        record: identifier,
        field,
        value: relationship.remoteState
      }, false);
    } else {
      replaceRelatedRecords(graph, {
        op: 'replaceRelatedRecords',
        record: identifier,
        field,
        value: relationship.remoteState.slice()
      }, false);
    }
  }

  /**
   *
   * Given RHS (Right Hand Side)
   *
   * ```ts
   * class User extends Model {
   *   @hasMany('animal', { async: false, inverse: 'owner' }) pets;
   * }
   * ```
   *
   * Given LHS (Left Hand Side)
   *
   * ```ts
   * class Animal extends Model {
   *  @belongsTo('user', { async: false, inverse: 'pets' }) owner;
   * }
   * ```
   *
   * The UpgradedMeta for the RHS would be:
   *
   * ```ts
   * {
   *   kind: 'hasMany',
   *   key: 'pets',
   *   type: 'animal',
   *   isAsync: false,
   *   isImplicit: false,
   *   isCollection: true,
   *   isPolymorphic: false,
   *   inverseKind: 'belongsTo',
   *   inverseKey: 'owner',
   *   inverseType: 'user',
   *   inverseIsAsync: false,
   *   inverseIsImplicit: false,
   *   inverseIsCollection: false,
   *   inverseIsPolymorphic: false,
   * }
   * ```
   *
   * The UpgradeMeta for the LHS would be:
   *
   * ```ts
   * {
   *   kind: 'belongsTo',
   *   key: 'owner',
   *   type: 'user',
   *   isAsync: false,
   *   isImplicit: false,
   *   isCollection: false,
   *   isPolymorphic: false,
   *   inverseKind: 'hasMany',
   *   inverseKey: 'pets',
   *   inverseType: 'animal',
   *   inverseIsAsync: false,
   *   inverseIsImplicit: false,
   *   inverseIsCollection: true,
   *   inverseIsPolymorphic: false,
   * }
   * ```
   *
   *
   * @class UpgradedMeta
   * @internal
   */
  const BOOL_LATER = null;
  const STR_LATER = '';
  const IMPLICIT_KEY_RAND = Date.now();
  function implicitKeyFor(type, key) {
    return `implicit-${type}:${key}${IMPLICIT_KEY_RAND}`;
  }
  function syncMeta(definition, inverseDefinition) {
    definition.inverseKind = inverseDefinition.kind;
    definition.inverseKey = inverseDefinition.key;
    definition.inverseType = inverseDefinition.type;
    definition.inverseIsAsync = inverseDefinition.isAsync;
    definition.inverseIsCollection = inverseDefinition.isCollection;
    definition.inverseIsPolymorphic = inverseDefinition.isPolymorphic;
    definition.inverseIsImplicit = inverseDefinition.isImplicit;
    const resetOnRemoteUpdate = definition.resetOnRemoteUpdate === false || inverseDefinition.resetOnRemoteUpdate === false ? false : true;
    definition.resetOnRemoteUpdate = resetOnRemoteUpdate;
    inverseDefinition.resetOnRemoteUpdate = resetOnRemoteUpdate;
  }
  function upgradeMeta(meta) {
    const niceMeta = {};
    const options = meta.options;
    niceMeta.kind = meta.kind;
    niceMeta.key = meta.name;
    niceMeta.type = meta.type;
    (true && !(typeof options?.async === 'boolean') && (0, _debug.assert)(`Expected relationship definition to specify async`, typeof options?.async === 'boolean'));
    niceMeta.isAsync = options.async;
    niceMeta.isImplicit = false;
    niceMeta.isCollection = meta.kind === 'hasMany';
    niceMeta.isPolymorphic = options && !!options.polymorphic;
    niceMeta.inverseKey = options && options.inverse || STR_LATER;
    niceMeta.inverseType = STR_LATER;
    niceMeta.inverseIsAsync = BOOL_LATER;
    niceMeta.inverseIsImplicit = options && options.inverse === null || BOOL_LATER;
    niceMeta.inverseIsCollection = BOOL_LATER;
    niceMeta.resetOnRemoteUpdate = options && options.resetOnRemoteUpdate === false ? false : true;
    return niceMeta;
  }
  function assertConfiguration(info, type, key) {
    if ((0, _runtime.macroCondition)((0, _runtime.config)("/home/tj/crimeisdown-v3/node_modules/@ember-data/graph").env.DEBUG)) {
      const isSelfReferential = info.isSelfReferential;
      if (isSelfReferential) {
        return true;
      }
      const _isRHS = key === info.rhs_relationshipName && (type === info.rhs_baseModelName ||
      // base or non-polymorphic
      // if the other side is polymorphic then we need to scan our modelNames
      info.lhs_isPolymorphic && info.rhs_modelNames.includes(type)); // polymorphic
      const _isLHS = key === info.lhs_relationshipName && (type === info.lhs_baseModelName ||
      // base or non-polymorphic
      // if the other side is polymorphic then we need to scan our modelNames
      info.rhs_isPolymorphic && info.lhs_modelNames.includes(type)); // polymorphic;

      if (!_isRHS && !_isLHS) {
        /*
          this occurs when we are likely polymorphic but not configured to be polymorphic
          most often due to extending a class that has a relationship definition on it.
           e.g.
           ```ts
          class Pet extends Model {
            @belongsTo('human', { async: false, inverse: 'pet' }) owner;
          }
          class Human extends Model {
            @belongsTo('pet', { async: false, inverse: 'owner' }) pet;
          }
          class Farmer extends Human {}
          ```
           In the above case, the following would trigger this error:
           ```ts
          let pet = store.createRecord('pet');
          let farmer = store.createRecord('farmer');
          farmer.pet = pet; // error
          ```
           The correct way to fix this is to specify the polymorphic option on Pet
          and to specify the abstract type 'human' on the Human base class.
           ```ts
          class Pet extends Model {
            @belongsTo('human', { async: false, inverse: 'pet', polymorphic: true }) owner;
          }
          class Human extends Model {
            @belongsTo('pet', { async: false, inverse: 'owner', as: 'human' }) pet;
          }
          class Farmer extends Human {}
          ```
           Alternatively both Human and Farmer could declare the relationship, because relationship
          definitions are "structural".
           ```ts
          class Pet extends Model {
            @belongsTo('human', { async: false, inverse: 'pet', polymorphic: true }) owner;
          }
          class Human extends Model {
            @belongsTo('pet', { async: false, inverse: 'owner', as: 'human' }) pet;
          }
          class Farmer extends Model {
            @belongsTo('pet', { async: false, inverse: 'owner', as: 'human' }) pet;
          }
          ```
          */
        if (key === info.lhs_relationshipName && info.lhs_modelNames.includes(type)) {
          // parentIdentifier, parentDefinition, addedIdentifier, store
          assertInheritedSchema(info.lhs_definition, type);
        } else if (key === info.rhs_relationshipName && info.rhs_modelNames.includes(type)) {
          assertInheritedSchema(info.lhs_definition, type);
        }
        // OPEN AN ISSUE :: we would like to improve our errors but need to understand what corner case got us here
        throw new Error(`PLEASE OPEN AN ISSUE :: Found a relationship that is neither the LHS nor RHS of the same edge. This is not supported. Please report this to the EmberData team.`);
      }
      if (_isRHS && _isLHS) {
        // not sure how we get here but it's probably the result of some form of inheritance
        // without having specified polymorphism correctly leading to it not being self-referential
        // OPEN AN ISSUE :: we would like to improve our errors but need to understand what corner case got us here
        throw new Error(`PLEASE OPEN AN ISSUE :: Found a relationship that is both the LHS and RHS of the same edge but is not self-referential. This is not supported. Please report this to the EmberData team.`);
      }
    }
  }
  function isLHS(info, type, key) {
    const isSelfReferential = info.isSelfReferential;
    const isRelationship = key === info.lhs_relationshipName;
    if ((0, _runtime.macroCondition)((0, _runtime.config)("/home/tj/crimeisdown-v3/node_modules/@ember-data/graph").env.DEBUG)) {
      assertConfiguration(info, type, key);
    }
    if (isRelationship === true) {
      return isSelfReferential === true ||
      // itself
      type === info.lhs_baseModelName ||
      // base or non-polymorphic
      // if the other side is polymorphic then we need to scan our modelNames
      info.rhs_isPolymorphic && info.lhs_modelNames.includes(type) // polymorphic
      ;
    }
    return false;
  }
  function upgradeDefinition(graph, identifier, propertyName, isImplicit = false) {
    const cache = graph._definitionCache;
    const storeWrapper = graph.store;
    const polymorphicLookup = graph._potentialPolymorphicTypes;
    const {
      type
    } = identifier;
    let cached = /*#__NOINLINE__*/expandingGet(cache, type, propertyName);

    // CASE: We have a cached resolution (null if no relationship exists)
    if (cached !== undefined) {
      return cached;
    }
    (true && !(!isImplicit) && (0, _debug.assert)(`Expected to find relationship definition in the cache for the implicit relationship ${propertyName}`, !isImplicit));
    const relationships = storeWrapper.getSchemaDefinitionService().relationshipsDefinitionFor(identifier);
    (true && !(relationships) && (0, _debug.assert)(`Expected to have a relationship definition for ${type} but none was found.`, relationships));
    const meta = relationships[propertyName];
    if (!meta) {
      // TODO potentially we should just be permissive here since this is an implicit relationship
      // and not require the lookup table to be populated
      if (polymorphicLookup[type]) {
        const altTypes = Object.keys(polymorphicLookup[type]);
        for (let i = 0; i < altTypes.length; i++) {
          const _cached = expandingGet(cache, altTypes[i], propertyName);
          if (_cached) {
            /*#__NOINLINE__*/expandingSet(cache, type, propertyName, _cached);
            _cached.rhs_modelNames.push(type);
            return _cached;
          }
        }
      }

      // CASE: We don't have a relationship at all
      // we should only hit this in prod
      (true && !(meta) && (0, _debug.assert)(`Expected a relationship schema for '${type}.${propertyName}', but no relationship schema was found.`, meta));
      cache[type][propertyName] = null;
      return null;
    }
    const definition = /*#__NOINLINE__*/upgradeMeta(meta);
    let inverseDefinition;
    let inverseKey;
    const inverseType = definition.type;

    // CASE: Inverse is explicitly null
    if (definition.inverseKey === null) {
      // TODO probably dont need this assertion if polymorphic
      (true && !(getStore(storeWrapper).modelFor(inverseType)) && (0, _debug.assert)(`Expected the inverse model to exist`, getStore(storeWrapper).modelFor(inverseType)));
      inverseDefinition = null;
    } else {
      inverseKey = /*#__NOINLINE__*/inverseForRelationship(getStore(storeWrapper), identifier, propertyName);

      // CASE: If we are polymorphic, and we declared an inverse that is non-null
      // we must assume that the lack of inverseKey means that there is no
      // concrete type as the baseType, so we must construct and artificial
      // placeholder
      if (!inverseKey && definition.isPolymorphic && definition.inverseKey) {
        inverseDefinition = {
          kind: 'belongsTo',
          // this must be updated when we find the first belongsTo or hasMany definition that matches
          key: definition.inverseKey,
          type: type,
          isAsync: false,
          // this must be updated when we find the first belongsTo or hasMany definition that matches
          isImplicit: false,
          isCollection: false,
          // this must be updated when we find the first belongsTo or hasMany definition that matches
          isPolymorphic: false
        }; // the rest of the fields are populated by syncMeta

        // CASE: Inverse resolves to null
      } else if (!inverseKey) {
        inverseDefinition = null;
      } else {
        // CASE: We have an explicit inverse or were able to resolve one
        const inverseDefinitions = storeWrapper.getSchemaDefinitionService().relationshipsDefinitionFor({
          type: inverseType
        });
        (true && !(inverseDefinitions) && (0, _debug.assert)(`Expected to have a relationship definition for ${inverseType} but none was found.`, inverseDefinitions));
        const metaFromInverse = inverseDefinitions[inverseKey];
        (true && !(metaFromInverse) && (0, _debug.assert)(`Expected a relationship schema for '${inverseType}.${inverseKey}' to match the inverse of '${type}.${propertyName}', but no relationship schema was found.`, metaFromInverse));
        inverseDefinition = upgradeMeta(metaFromInverse);
      }
    }

    // CASE: We have no inverse
    if (!inverseDefinition) {
      // polish off meta
      inverseKey = /*#__NOINLINE__*/implicitKeyFor(type, propertyName);
      inverseDefinition = {
        kind: 'implicit',
        key: inverseKey,
        type: type,
        isAsync: false,
        isImplicit: true,
        isCollection: true,
        // with implicits any number of records could point at us
        isPolymorphic: false
      }; // the rest of the fields are populated by syncMeta

      syncMeta(definition, inverseDefinition);
      syncMeta(inverseDefinition, definition);
      const info = {
        lhs_key: `${type}:${propertyName}`,
        lhs_modelNames: [type],
        lhs_baseModelName: type,
        lhs_relationshipName: propertyName,
        lhs_definition: definition,
        lhs_isPolymorphic: definition.isPolymorphic,
        rhs_key: inverseDefinition.key,
        rhs_modelNames: [inverseType],
        rhs_baseModelName: inverseType,
        rhs_relationshipName: inverseDefinition.key,
        rhs_definition: inverseDefinition,
        rhs_isPolymorphic: false,
        hasInverse: false,
        isSelfReferential: type === inverseType,
        // this could be wrong if we are self-referential but also polymorphic
        isReflexive: false // we can't be reflexive if we don't define an inverse
      };
      expandingSet(cache, inverseType, inverseKey, info);
      expandingSet(cache, type, propertyName, info);
      return info;
    }

    // CASE: We do have an inverse
    const baseType = inverseDefinition.type;

    // TODO we want to assert this but this breaks all of our shoddily written tests
    /*
      if (DEBUG) {
        let inverseDoubleCheck = inverseFor(inverseRelationshipName, store);
         assert(`The ${inverseBaseModelName}:${inverseRelationshipName} relationship declares 'inverse: null', but it was resolved as the inverse for ${baseModelName}:${relationshipName}.`, inverseDoubleCheck);
      }
    */
    // CASE: We may have already discovered the inverse for the baseModelName
    // CASE: We have already discovered the inverse
    (true && !(typeof inverseKey === 'string' && inverseKey.length > 0) && (0, _debug.assert)(`We should have determined an inverseKey by now, open an issue if this is hit`, typeof inverseKey === 'string' && inverseKey.length > 0));
    cached = expandingGet(cache, baseType, propertyName) || expandingGet(cache, inverseType, inverseKey);
    if (cached) {
      // TODO this assert can be removed if the above assert is enabled
      (true && !(cached.hasInverse !== false) && (0, _debug.assert)(`The ${inverseType}:${inverseKey} relationship declares 'inverse: null', but it was resolved as the inverse for ${type}:${propertyName}.`, cached.hasInverse !== false));
      const _isLHS = cached.lhs_baseModelName === baseType;
      const modelNames = _isLHS ? cached.lhs_modelNames : cached.rhs_modelNames;
      // make this lookup easier in the future by caching the key
      modelNames.push(type);
      expandingSet(cache, type, propertyName, cached);
      return cached;
    }

    // this is our first time so polish off the metas
    syncMeta(definition, inverseDefinition);
    syncMeta(inverseDefinition, definition);
    const lhs_modelNames = [type];
    if (type !== baseType) {
      lhs_modelNames.push(baseType);
    }
    const isSelfReferential = baseType === inverseType;
    const info = {
      lhs_key: `${baseType}:${propertyName}`,
      lhs_modelNames,
      lhs_baseModelName: baseType,
      lhs_relationshipName: propertyName,
      lhs_definition: definition,
      lhs_isPolymorphic: definition.isPolymorphic,
      rhs_key: `${inverseType}:${inverseKey}`,
      rhs_modelNames: [inverseType],
      rhs_baseModelName: inverseType,
      rhs_relationshipName: inverseKey,
      rhs_definition: inverseDefinition,
      rhs_isPolymorphic: inverseDefinition.isPolymorphic,
      hasInverse: true,
      isSelfReferential,
      isReflexive: isSelfReferential && propertyName === inverseKey
    };

    // Create entries for the baseModelName as well as modelName to speed up
    //  inverse lookups
    expandingSet(cache, baseType, propertyName, info);
    expandingSet(cache, type, propertyName, info);

    // Greedily populate the inverse
    expandingSet(cache, inverseType, inverseKey, info);
    return info;
  }
  function inverseForRelationship(store, identifier, key) {
    const definition = store.getSchemaDefinitionService().relationshipsDefinitionFor(identifier)[key];
    if (!definition) {
      return null;
    }
    (true && !(definition.options?.inverse === null || typeof definition.options?.inverse === 'string' && definition.options.inverse.length > 0) && (0, _debug.assert)(`Expected the relationship defintion to specify the inverse type or null.`, definition.options?.inverse === null || typeof definition.options?.inverse === 'string' && definition.options.inverse.length > 0));
    return definition.options.inverse;
  }
  function createState() {
    return {
      hasReceivedData: false,
      isEmpty: true,
      isStale: false,
      hasFailedLoadAttempt: false,
      shouldForceReload: false,
      hasDematerializedInverse: false
    };
  }
  function createCollectionEdge(definition, identifier) {
    return {
      definition,
      identifier,
      state: createState(),
      remoteMembers: new Set(),
      remoteState: [],
      additions: null,
      removals: null,
      meta: null,
      links: null,
      localState: null,
      isDirty: true,
      transactionRef: 0,
      _diff: undefined
    };
  }
  function legacyGetCollectionRelationshipData(source) {
    const payload = {};
    if (source.state.hasReceivedData) {
      payload.data = computeLocalState(source);
    }
    if (source.links) {
      payload.links = source.links;
    }
    if (source.meta) {
      payload.meta = source.meta;
    }
    return payload;
  }
  function createImplicitEdge(definition, identifier) {
    return {
      definition,
      identifier,
      localMembers: new Set(),
      remoteMembers: new Set()
    };
  }

  /*
   * @module @ember-data/graph
   *
   * Stores the data for one side of a "single" resource relationship.
   *
   * @class ResourceEdge
   * @internal
   */

  function createResourceEdge(definition, identifier) {
    return {
      definition,
      identifier,
      state: createState(),
      transactionRef: 0,
      localState: null,
      remoteState: null,
      meta: null,
      links: null
    };
  }
  function legacyGetResourceRelationshipData(source) {
    let data;
    const payload = {};
    if (source.localState) {
      data = source.localState;
    }
    if (source.localState === null && source.state.hasReceivedData) {
      data = null;
    }
    if (source.links) {
      payload.links = source.links;
    }
    if (data !== undefined) {
      payload.data = data;
    }
    if (source.meta) {
      payload.meta = source.meta;
    }
    return payload;
  }
  function addToRelatedRecords(graph, op, isRemote) {
    (true && !(!isRemote) && (0, _debug.assert)(`Graph does not yet support updating the remote state of a relationship via the ${op.op} operation`, !isRemote));
    const {
      record,
      value,
      index
    } = op;
    const relationship = graph.get(record, op.field);
    (true && !(isHasMany(relationship)) && (0, _debug.assert)(`You can only '${op.op}' on a hasMany relationship. ${record.type}.${op.field} is a ${relationship.definition.kind}`, isHasMany(relationship)));
    if (Array.isArray(value)) {
      for (let i = 0; i < value.length; i++) {
        addRelatedRecord(graph, relationship, record, value[i], index !== undefined ? index + i : index, isRemote);
      }
    } else {
      addRelatedRecord(graph, relationship, record, value, index, isRemote);
    }
    notifyChange(graph, relationship.identifier, relationship.definition.key);
  }
  function addRelatedRecord(graph, relationship, record, value, index, isRemote) {
    (true && !(value) && (0, _debug.assert)(`expected an identifier to add to the collection relationship`, value));
    if (_addLocal(graph, record, relationship, value, index ?? null)) {
      addToInverse(graph, value, relationship.definition.inverseKey, record, isRemote);
    }
  }
  function mergeIdentifier(graph, op, relationships) {
    Object.keys(relationships).forEach(key => {
      const rel = relationships[key];
      if (!rel) {
        return;
      }
      mergeIdentifierForRelationship(graph, op, rel);
    });
  }
  function mergeIdentifierForRelationship(graph, op, rel) {
    rel.identifier = op.value;
    forAllRelatedIdentifiers(rel, identifier => {
      const inverse = graph.get(identifier, rel.definition.inverseKey);
      mergeInRelationship(graph, inverse, op);
    });
  }
  function mergeInRelationship(graph, rel, op) {
    if (isBelongsTo(rel)) {
      mergeBelongsTo(graph, rel, op);
    } else if (isHasMany(rel)) {
      mergeHasMany(graph, rel, op);
    } else {
      mergeImplicit(graph, rel, op);
    }
  }
  function mergeBelongsTo(graph, rel, op) {
    if (rel.remoteState === op.record) {
      rel.remoteState = op.value;
    }
    if (rel.localState === op.record) {
      rel.localState = op.record;
      notifyChange(graph, rel.identifier, rel.definition.key);
    }
  }
  function mergeHasMany(graph, rel, op) {
    if (rel.remoteMembers.has(op.record)) {
      rel.remoteMembers.delete(op.record);
      rel.remoteMembers.add(op.value);
      const index = rel.remoteState.indexOf(op.record);
      rel.remoteState.splice(index, 1, op.value);
      rel.isDirty = true;
    }
    if (rel.additions?.has(op.record)) {
      rel.additions.delete(op.record);
      rel.additions.add(op.value);
      rel.isDirty = true;
    }
    if (rel.removals?.has(op.record)) {
      rel.removals.delete(op.record);
      rel.removals.add(op.value);
      rel.isDirty = true;
    }
    if (rel.isDirty) {
      notifyChange(graph, rel.identifier, rel.definition.key);
    }
  }
  function mergeImplicit(graph, rel, op) {
    if (rel.remoteMembers.has(op.record)) {
      rel.remoteMembers.delete(op.record);
      rel.remoteMembers.add(op.value);
    }
    if (rel.localMembers.has(op.record)) {
      rel.localMembers.delete(op.record);
      rel.localMembers.add(op.value);
    }
  }
  function removeFromRelatedRecords(graph, op, isRemote) {
    (true && !(!isRemote) && (0, _debug.assert)(`Graph does not yet support updating the remote state of a relationship via the ${op.op} operation`, !isRemote));
    const {
      record,
      value
    } = op;
    const relationship = graph.get(record, op.field);
    (true && !(isHasMany(relationship)) && (0, _debug.assert)(`You can only '${op.op}' on a hasMany relationship. ${record.type}.${op.field} is a ${relationship.definition.kind}`, isHasMany(relationship))); // TODO we should potentially thread the index information through here
    // when available as it may make it faster to remove from the local state
    // when trying to patch more efficiently without blowing away the entire
    // local state array
    if (Array.isArray(value)) {
      for (let i = 0; i < value.length; i++) {
        removeRelatedRecord(graph, relationship, record, value[i], isRemote);
      }
    } else {
      removeRelatedRecord(graph, relationship, record, value, isRemote);
    }
    notifyChange(graph, relationship.identifier, relationship.definition.key);
  }
  function removeRelatedRecord(graph, relationship, record, value, isRemote) {
    (true && !(value) && (0, _debug.assert)(`expected an identifier to remove from the collection relationship`, value));
    if (_removeLocal(relationship, value)) {
      removeFromInverse(graph, value, relationship.definition.inverseKey, record, isRemote);
    }
  }

  /*
    This method normalizes a link to an "links object". If the passed link is
    already an object it's returned without any modifications.
  
    See http://jsonapi.org/format/#document-links for more information.
  */
  function _normalizeLink(link) {
    switch (typeof link) {
      case 'object':
        return link;
      case 'string':
        return {
          href: link
        };
    }
  }

  /*
      Updates the "canonical" or "remote" state of a relationship, replacing any existing
      state and blowing away any local changes (excepting new records).
  */
  function updateRelationshipOperation(graph, op) {
    const relationship = graph.get(op.record, op.field);
    (true && !(isHasMany(relationship) || isBelongsTo(relationship)) && (0, _debug.assert)(`Cannot update an implicit relationship`, isHasMany(relationship) || isBelongsTo(relationship)));
    const {
      definition,
      state,
      identifier
    } = relationship;
    const {
      isCollection
    } = definition;
    const payload = op.value;
    let hasRelationshipDataProperty = false;
    let hasUpdatedLink = false;
    if (payload.meta) {
      relationship.meta = payload.meta;
    }
    if (payload.data !== undefined) {
      hasRelationshipDataProperty = true;
      if (isCollection) {
        // TODO deprecate this case. We
        // have tests saying we support it.
        if (payload.data === null) {
          payload.data = [];
        }
        (true && !(Array.isArray(payload.data)) && (0, _debug.assert)(`Expected an array`, Array.isArray(payload.data)));
        const cache = graph.store.identifierCache;
        graph.update({
          op: 'replaceRelatedRecords',
          record: identifier,
          field: op.field,
          value: upgradeIdentifiers(payload.data, cache)
        }, true);
      } else {
        graph.update({
          op: 'replaceRelatedRecord',
          record: identifier,
          field: op.field,
          value: payload.data ? graph.store.identifierCache.upgradeIdentifier(payload.data) : null
        }, true);
      }
    } else if (definition.isAsync === false && !state.hasReceivedData) {
      hasRelationshipDataProperty = true;
      if (isCollection) {
        graph.update({
          op: 'replaceRelatedRecords',
          record: identifier,
          field: op.field,
          value: []
        }, true);
      } else {
        graph.update({
          op: 'replaceRelatedRecord',
          record: identifier,
          field: op.field,
          value: null
        }, true);
      }
    }
    if (payload.links) {
      const originalLinks = relationship.links;
      relationship.links = payload.links;
      if (payload.links.related) {
        const relatedLink = _normalizeLink(payload.links.related);
        const currentLink = originalLinks && originalLinks.related ? _normalizeLink(originalLinks.related) : null;
        const currentLinkHref = currentLink ? currentLink.href : null;
        if (relatedLink && relatedLink.href && relatedLink.href !== currentLinkHref) {
          (true && (0, _debug.warn)(`You pushed a record of type '${identifier.type}' with a relationship '${definition.key}' configured as 'async: false'. You've included a link but no primary data, this may be an error in your payload. EmberData will treat this relationship as known-to-be-empty.`, definition.isAsync || state.hasReceivedData, {
            id: 'ds.store.push-link-for-sync-relationship'
          }));
          (true && !(typeof relatedLink.href === 'string' || relatedLink.href === null) && (0, _debug.assert)(`You have pushed a record of type '${identifier.type}' with '${definition.key}' as a link, but the value of that link is not a string.`, typeof relatedLink.href === 'string' || relatedLink.href === null));
          hasUpdatedLink = true;
        }
      }
    }

    /*
         Data being pushed into the relationship might contain only data or links,
         or a combination of both.
          IF contains only data
         IF contains both links and data
          state.isEmpty -> true if is empty array (has-many) or is null (belongs-to)
          state.hasReceivedData -> true
          hasDematerializedInverse -> false
          state.isStale -> false
          allInverseRecordsAreLoaded -> run-check-to-determine
          IF contains only links
          state.isStale -> true
         */
    relationship.state.hasFailedLoadAttempt = false;
    if (hasRelationshipDataProperty) {
      const relationshipIsEmpty = payload.data === null || Array.isArray(payload.data) && payload.data.length === 0;

      // we don't need to notify here as the update op we pushed in above will notify once
      // membership is in the correct state.
      relationship.state.hasReceivedData = true;
      relationship.state.isStale = false;
      relationship.state.hasDematerializedInverse = false;
      relationship.state.isEmpty = relationshipIsEmpty;
    } else if (hasUpdatedLink) {
      // only notify stale if we have not previously received membership data.
      // within this same transaction
      // this prevents refetching when only one side of the relationship in the
      // payload contains the info while the other side contains just a link
      // this only works when the side with just a link is a belongsTo, as we
      // don't know if a hasMany has full information or not.
      // see #7049 for context.
      if (isCollection || !relationship.state.hasReceivedData || isStaleTransaction(relationship.transactionRef, graph._transaction)) {
        relationship.state.isStale = true;
        notifyChange(graph, relationship.identifier, relationship.definition.key);
      } else {
        relationship.state.isStale = false;
      }
    }
  }
  function isStaleTransaction(relationshipTransactionId, graphTransactionId) {
    return relationshipTransactionId === 0 ||
    // relationship has never notified
    graphTransactionId === null ||
    // we are not in a transaction
    relationshipTransactionId < graphTransactionId // we are not part of the current transaction
    ;
  }
  function upgradeIdentifiers(arr, cache) {
    for (let i = 0; i < arr.length; i++) {
      arr[i] = cache.upgradeIdentifier(arr[i]);
    }
    return arr;
  }
  const Graphs = new Map();
  let transactionRef = 0;
  /*
   * Graph acts as the cache for relationship data. It allows for
   * us to ask about and update relationships for a given Identifier
   * without requiring other objects for that Identifier to be
   * instantiated (such as `RecordData` or a `Record`)
   *
   * This also allows for us to make more substantive changes to relationships
   * with increasingly minor alterations to other portions of the internals
   * over time.
   *
   * The graph is made up of nodes and edges. Each unique identifier gets
   * its own node, which is a dictionary with a list of that node's edges
   * (or connections) to other nodes. In `Model` terms, a node represents a
   * record instance, with each key (an edge) in the dictionary correlating
   * to either a `hasMany` or `belongsTo` field on that record instance.
   *
   * The value for each key, or `edge` is the identifier(s) the node relates
   * to in the graph from that key.
   */
  class Graph {
    constructor(store) {
      this._definitionCache = Object.create(null);
      this._metaCache = Object.create(null);
      this._potentialPolymorphicTypes = Object.create(null);
      this.identifiers = new Map();
      this.store = store;
      this.isDestroyed = false;
      this._willSyncRemote = false;
      this._willSyncLocal = false;
      this._pushedUpdates = {
        belongsTo: undefined,
        hasMany: undefined,
        deletions: []
      };
      this._updatedRelationships = new Set();
      this._transaction = null;
      this._removing = null;
      this.silenceNotifications = false;
    }
    has(identifier, propertyName) {
      const relationships = this.identifiers.get(identifier);
      if (!relationships) {
        return false;
      }
      return relationships[propertyName] !== undefined;
    }
    getDefinition(identifier, propertyName) {
      let defs = this._metaCache[identifier.type];
      let meta = defs?.[propertyName];
      if (!meta) {
        const info = /*#__NOINLINE__*/upgradeDefinition(this, identifier, propertyName);
        (true && !(info !== null) && (0, _debug.assert)(`Could not determine relationship information for ${identifier.type}.${propertyName}`, info !== null)); // if (info.rhs_definition?.kind === 'implicit') {
        // we should possibly also do this
        // but it would result in being extremely permissive for other relationships by accident
        // this.registerPolymorphicType(info.rhs_baseModelName, identifier.type);
        // }
        meta = /*#__NOINLINE__*/isLHS(info, identifier.type, propertyName) ? info.lhs_definition : info.rhs_definition;
        defs = this._metaCache[identifier.type] = defs || {};
        defs[propertyName] = meta;
      }
      return meta;
    }
    get(identifier, propertyName) {
      (true && !(propertyName) && (0, _debug.assert)(`expected propertyName`, propertyName));
      let relationships = this.identifiers.get(identifier);
      if (!relationships) {
        relationships = Object.create(null);
        this.identifiers.set(identifier, relationships);
      }
      let relationship = relationships[propertyName];
      if (!relationship) {
        const meta = this.getDefinition(identifier, propertyName);
        if (meta.kind === 'belongsTo') {
          relationship = relationships[propertyName] = createResourceEdge(meta, identifier);
        } else if (meta.kind === 'hasMany') {
          relationship = relationships[propertyName] = createCollectionEdge(meta, identifier);
        } else {
          (true && !(meta.kind === 'implicit' && meta.isImplicit === true) && (0, _debug.assert)(`Expected kind to be implicit`, meta.kind === 'implicit' && meta.isImplicit === true));
          relationship = relationships[propertyName] = createImplicitEdge(meta, identifier);
        }
      }
      return relationship;
    }
    getData(identifier, propertyName) {
      const relationship = this.get(identifier, propertyName);
      (true && !(!isImplicit(relationship)) && (0, _debug.assert)(`Cannot getData() on an implicit relationship`, !isImplicit(relationship)));
      if (isBelongsTo(relationship)) {
        return legacyGetResourceRelationshipData(relationship);
      }
      return legacyGetCollectionRelationshipData(relationship);
    }

    /*
     * Allows for the graph to dynamically discover polymorphic connections
     * without needing to walk prototype chains.
     *
     * Used by edges when an added `type` does not match the expected `type`
     * for that edge.
     *
     * Currently we assert before calling this. For a public API we will want
     * to call out to the schema manager to ask if we should consider these
     * types as equivalent for a given relationship.
     */
    registerPolymorphicType(type1, type2) {
      const typeCache = this._potentialPolymorphicTypes;
      let t1 = typeCache[type1];
      if (!t1) {
        t1 = typeCache[type1] = Object.create(null);
      }
      t1[type2] = true;
      let t2 = typeCache[type2];
      if (!t2) {
        t2 = typeCache[type2] = Object.create(null);
      }
      t2[type1] = true;
    }

    /*
     TODO move this comment somewhere else
     implicit relationships are relationships which have not been declared but the inverse side exists on
     another record somewhere
      For example if there was:
      ```app/models/comment.js
     import Model, { attr } from '@ember-data/model';
      export default class Comment extends Model {
       @attr text;
     }
     ```
      and there is also:
      ```app/models/post.js
     import Model, { attr, hasMany } from '@ember-data/model';
      export default class Post extends Model {
       @attr title;
       @hasMany('comment', { async: true, inverse: null }) comments;
     }
     ```
      Then we would have a implicit 'post' relationship for the comment record in order
     to be do things like remove the comment from the post if the comment were to be deleted.
    */

    isReleasable(identifier) {
      const relationships = this.identifiers.get(identifier);
      if (!relationships) {
        if ((0, _runtime.macroCondition)((0, _runtime.config)("/home/tj/crimeisdown-v3/node_modules/@ember-data/graph").debug.LOG_GRAPH)) {
          // eslint-disable-next-line no-console
          console.log(`graph: RELEASABLE ${String(identifier)}`);
        }
        return true;
      }
      const keys = Object.keys(relationships);
      for (let i = 0; i < keys.length; i++) {
        const relationship = relationships[keys[i]];
        // account for previously unloaded relationships
        // typically from a prior deletion of a record that pointed to this one implicitly
        if (relationship === undefined) {
          continue;
        }
        (true && !(relationship) && (0, _debug.assert)(`Expected a relationship`, relationship));
        if (relationship.definition.inverseIsAsync && !isNew(identifier)) {
          if ((0, _runtime.macroCondition)((0, _runtime.config)("/home/tj/crimeisdown-v3/node_modules/@ember-data/graph").debug.LOG_GRAPH)) {
            // eslint-disable-next-line no-console
            console.log(`graph: <<NOT>> RELEASABLE ${String(identifier)}`);
          }
          return false;
        }
      }
      if ((0, _runtime.macroCondition)((0, _runtime.config)("/home/tj/crimeisdown-v3/node_modules/@ember-data/graph").debug.LOG_GRAPH)) {
        // eslint-disable-next-line no-console
        console.log(`graph: RELEASABLE ${String(identifier)}`);
      }
      return true;
    }
    unload(identifier, silenceNotifications) {
      if ((0, _runtime.macroCondition)((0, _runtime.config)("/home/tj/crimeisdown-v3/node_modules/@ember-data/graph").debug.LOG_GRAPH)) {
        // eslint-disable-next-line no-console
        console.log(`graph: unload ${String(identifier)}`);
      }
      const relationships = this.identifiers.get(identifier);
      if (relationships) {
        // cleans up the graph but retains some nodes
        // to allow for rematerialization
        Object.keys(relationships).forEach(key => {
          const rel = relationships[key];
          if (!rel) {
            return;
          }
          /*#__NOINLINE__*/
          destroyRelationship(this, rel, silenceNotifications);
          if ( /*#__NOINLINE__*/isImplicit(rel)) {
            // @ts-expect-error
            relationships[key] = undefined;
          }
        });
      }
    }
    _isDirty(identifier, field) {
      const relationships = this.identifiers.get(identifier);
      if (!relationships) {
        return false;
      }
      const relationship = relationships[field];
      if (!relationship) {
        return false;
      }
      if (isBelongsTo(relationship)) {
        return relationship.localState !== relationship.remoteState;
      } else if (isHasMany(relationship)) {
        const hasAdditions = relationship.additions !== null && relationship.additions.size > 0;
        const hasRemovals = relationship.removals !== null && relationship.removals.size > 0;
        return hasAdditions || hasRemovals || isReordered(relationship);
      }
      return false;
    }
    getChanged(identifier) {
      const relationships = this.identifiers.get(identifier);
      const changed = new Map();
      if (!relationships) {
        return changed;
      }
      const keys = Object.keys(relationships);
      for (let i = 0; i < keys.length; i++) {
        const field = keys[i];
        const relationship = relationships[field];
        if (!relationship) {
          continue;
        }
        if (isBelongsTo(relationship)) {
          if (relationship.localState !== relationship.remoteState) {
            changed.set(field, {
              kind: 'resource',
              remoteState: relationship.remoteState,
              localState: relationship.localState
            });
          }
        } else if (isHasMany(relationship)) {
          const hasAdditions = relationship.additions !== null && relationship.additions.size > 0;
          const hasRemovals = relationship.removals !== null && relationship.removals.size > 0;
          const reordered = isReordered(relationship);
          if (hasAdditions || hasRemovals || reordered) {
            changed.set(field, {
              kind: 'collection',
              additions: new Set(relationship.additions) || new Set(),
              removals: new Set(relationship.removals) || new Set(),
              remoteState: relationship.remoteState,
              localState: legacyGetCollectionRelationshipData(relationship).data || [],
              reordered
            });
          }
        }
      }
      return changed;
    }
    hasChanged(identifier) {
      const relationships = this.identifiers.get(identifier);
      if (!relationships) {
        return false;
      }
      const keys = Object.keys(relationships);
      for (let i = 0; i < keys.length; i++) {
        if (this._isDirty(identifier, keys[i])) {
          return true;
        }
      }
      return false;
    }
    rollback(identifier) {
      const relationships = this.identifiers.get(identifier);
      const changed = [];
      if (!relationships) {
        return changed;
      }
      const keys = Object.keys(relationships);
      for (let i = 0; i < keys.length; i++) {
        const field = keys[i];
        const relationship = relationships[field];
        if (!relationship) {
          continue;
        }
        if (this._isDirty(identifier, field)) {
          rollbackRelationship(this, identifier, field, relationship);
          changed.push(field);
        }
      }
      return changed;
    }
    remove(identifier) {
      if ((0, _runtime.macroCondition)((0, _runtime.config)("/home/tj/crimeisdown-v3/node_modules/@ember-data/graph").debug.LOG_GRAPH)) {
        // eslint-disable-next-line no-console
        console.log(`graph: remove ${String(identifier)}`);
      }
      (true && !(!this._removing) && (0, _debug.assert)(`Cannot remove ${String(identifier)} while still removing ${String(this._removing)}`, !this._removing));
      this._removing = identifier;
      this.unload(identifier);
      this.identifiers.delete(identifier);
      this._removing = null;
    }

    /*
     * Remote state changes
     */
    push(op) {
      if ((0, _runtime.macroCondition)((0, _runtime.config)("/home/tj/crimeisdown-v3/node_modules/@ember-data/graph").debug.LOG_GRAPH)) {
        // eslint-disable-next-line no-console
        console.log(`graph: push ${String(op.record)}`, op);
      }
      if (op.op === 'deleteRecord') {
        this._pushedUpdates.deletions.push(op);
      } else {
        const definition = this.getDefinition(op.record, op.field);
        (true && !(definition.kind !== 'implicit') && (0, _debug.assert)(`Cannot push a remote update for an implicit relationship`, definition.kind !== 'implicit'));
        addPending(this._pushedUpdates, definition, op);
      }
      if (!this._willSyncRemote) {
        this._willSyncRemote = true;
        getStore(this.store)._schedule('coalesce', () => this._flushRemoteQueue());
      }
    }

    /*
     * Local state changes
     */

    update(op, isRemote = false) {
      (true && !(op.op === 'deleteRecord' || op.op === 'mergeIdentifiers' || !isImplicit(this.get(op.record, op.field))) && (0, _debug.assert)(`Cannot update an implicit relationship`, op.op === 'deleteRecord' || op.op === 'mergeIdentifiers' || !isImplicit(this.get(op.record, op.field))));
      if ((0, _runtime.macroCondition)((0, _runtime.config)("/home/tj/crimeisdown-v3/node_modules/@ember-data/graph").debug.LOG_GRAPH)) {
        // eslint-disable-next-line no-console
        console.log(`graph: update (${isRemote ? 'remote' : 'local'}) ${String(op.record)}`, op);
      }
      switch (op.op) {
        case 'mergeIdentifiers':
          {
            const relationships = this.identifiers.get(op.record);
            if (relationships) {
              /*#__NOINLINE__*/mergeIdentifier(this, op, relationships);
            }
            break;
          }
        case 'updateRelationship':
          (true && !(isRemote) && (0, _debug.assert)(`Can only perform the operation updateRelationship on remote state`, isRemote));
          if ((0, _runtime.macroCondition)((0, _runtime.config)("/home/tj/crimeisdown-v3/node_modules/@ember-data/graph").env.DEBUG)) {
            // in debug, assert payload validity eagerly
            // TODO add deprecations/assertion here for duplicates
            assertValidRelationshipPayload(this, op);
          }
          /*#__NOINLINE__*/
          updateRelationshipOperation(this, op);
          break;
        case 'deleteRecord':
          {
            (true && !(isRemote) && (0, _debug.assert)(`Can only perform the operation deleteRelationship on remote state`, isRemote));
            const identifier = op.record;
            const relationships = this.identifiers.get(identifier);
            if (relationships) {
              Object.keys(relationships).forEach(key => {
                const rel = relationships[key];
                if (!rel) {
                  return;
                }
                // works together with the has check
                // @ts-expect-error
                relationships[key] = undefined;
                /*#__NOINLINE__*/
                removeCompletelyFromInverse(this, rel);
              });
              this.identifiers.delete(identifier);
            }
            break;
          }
        case 'replaceRelatedRecord':
          /*#__NOINLINE__*/replaceRelatedRecord(this, op, isRemote);
          break;
        case 'addToRelatedRecords':
          // we will lift this restriction once the cache is allowed to make remote updates directly
          (true && !(!isRemote) && (0, _debug.assert)(`Can only perform the operation addToRelatedRecords on local state`, !isRemote));
          /*#__NOINLINE__*/
          addToRelatedRecords(this, op, isRemote);
          break;
        case 'removeFromRelatedRecords':
          // we will lift this restriction once the cache is allowed to make remote updates directly
          (true && !(!isRemote) && (0, _debug.assert)(`Can only perform the operation removeFromRelatedRecords on local state`, !isRemote));
          /*#__NOINLINE__*/
          removeFromRelatedRecords(this, op, isRemote);
          break;
        case 'replaceRelatedRecords':
          /*#__NOINLINE__*/replaceRelatedRecords(this, op, isRemote);
          break;
        default:
          (true && !(false) && (0, _debug.assert)(`No local relationship update operation exists for '${op.op}'`));
      }
    }
    _scheduleLocalSync(relationship) {
      this._updatedRelationships.add(relationship);
      if (!this._willSyncLocal) {
        this._willSyncLocal = true;
        getStore(this.store)._schedule('sync', () => this._flushLocalQueue());
      }
    }
    _flushRemoteQueue() {
      if (!this._willSyncRemote) {
        return;
      }
      if ((0, _runtime.macroCondition)((0, _runtime.config)("/home/tj/crimeisdown-v3/node_modules/@ember-data/graph").debug.LOG_GRAPH)) {
        // eslint-disable-next-line no-console
        console.groupCollapsed(`Graph: Initialized Transaction`);
      }
      this._transaction = ++transactionRef;
      this._willSyncRemote = false;
      const updates = this._pushedUpdates;
      const {
        deletions,
        hasMany,
        belongsTo
      } = updates;
      updates.deletions = [];
      updates.hasMany = undefined;
      updates.belongsTo = undefined;
      for (let i = 0; i < deletions.length; i++) {
        this.update(deletions[i], true);
      }
      if (hasMany) {
        flushPending(this, hasMany);
      }
      if (belongsTo) {
        flushPending(this, belongsTo);
      }
      this._transaction = null;
      if ((0, _runtime.macroCondition)((0, _runtime.config)("/home/tj/crimeisdown-v3/node_modules/@ember-data/graph").debug.LOG_GRAPH)) {
        // eslint-disable-next-line no-console
        console.log(`Graph: transaction finalized`);
        // eslint-disable-next-line no-console
        console.groupEnd();
      }
    }
    _addToTransaction(relationship) {
      (true && !(this._transaction !== null) && (0, _debug.assert)(`expected a transaction`, this._transaction !== null));
      if ((0, _runtime.macroCondition)((0, _runtime.config)("/home/tj/crimeisdown-v3/node_modules/@ember-data/graph").debug.LOG_GRAPH)) {
        // eslint-disable-next-line no-console
        console.log(`Graph: ${String(relationship.identifier)} ${relationship.definition.key} added to transaction`);
      }
      relationship.transactionRef = this._transaction;
    }
    _flushLocalQueue() {
      if (!this._willSyncLocal) {
        return;
      }
      if (this.silenceNotifications) {
        this.silenceNotifications = false;
        this._updatedRelationships = new Set();
        return;
      }
      this._willSyncLocal = false;
      const updated = this._updatedRelationships;
      this._updatedRelationships = new Set();
      updated.forEach(rel => notifyChange(this, rel.identifier, rel.definition.key));
    }
    destroy() {
      Graphs.delete(this.store);
      if ((0, _runtime.macroCondition)((0, _runtime.config)("/home/tj/crimeisdown-v3/node_modules/@ember-data/graph").env.DEBUG)) {
        Graphs.delete(getStore(this.store));
        if (Graphs.size) {
          Graphs.forEach((_, key) => {
            (true && !(
            // @ts-expect-error
            !key.isDestroyed && !key.isDestroying) && (0, _debug.assert)(`Memory Leak Detected, likely the test or app instance previous to this was not torn down properly`, !key.isDestroyed && !key.isDestroying));
          });
        }
      }
      this.identifiers.clear();
      this.store = null;
      this.isDestroyed = true;
    }
  }
  function flushPending(graph, ops) {
    ops.forEach(type => {
      type.forEach(opList => {
        flushPendingList(graph, opList);
      });
    });
  }
  function flushPendingList(graph, opList) {
    for (let i = 0; i < opList.length; i++) {
      graph.update(opList[i], true);
    }
  }

  // Handle dematerialization for relationship `rel`.  In all cases, notify the
  // relationship of the dematerialization: this is done so the relationship can
  // notify its inverse which needs to update state
  //
  // If the inverse is sync, unloading this record is treated as a client-side
  // delete, so we remove the inverse records from this relationship to
  // disconnect the graph.  Because it's not async, we don't need to keep around
  // the identifier as an id-wrapper for references
  function destroyRelationship(graph, rel, silenceNotifications) {
    if (isImplicit(rel)) {
      if (graph.isReleasable(rel.identifier)) {
        /*#__NOINLINE__*/removeCompletelyFromInverse(graph, rel);
      }
      return;
    }
    const {
      identifier
    } = rel;
    const {
      inverseKey
    } = rel.definition;
    if (!rel.definition.inverseIsImplicit) {
      /*#__NOINLINE__*/forAllRelatedIdentifiers(rel, inverseIdentifer => /*#__NOINLINE__*/notifyInverseOfDematerialization(graph, inverseIdentifer, inverseKey, identifier, silenceNotifications));
    }
    if (!rel.definition.inverseIsImplicit && !rel.definition.inverseIsAsync) {
      rel.state.isStale = true;
      /*#__NOINLINE__*/
      clearRelationship(rel);

      // necessary to clear relationships in the ui from dematerialized records
      // hasMany is managed by Model which calls `retreiveLatest` after
      // dematerializing the resource-cache instance.
      // but sync belongsTo requires this since they don't have a proxy to update.
      // so we have to notify so it will "update" to null.
      // we should discuss whether we still care about this, probably fine to just
      // leave the ui relationship populated since the record is destroyed and
      // internally we've fully cleaned up.
      if (!rel.definition.isAsync && !silenceNotifications) {
        /*#__NOINLINE__*/notifyChange(graph, rel.identifier, rel.definition.key);
      }
    }
  }
  function notifyInverseOfDematerialization(graph, inverseIdentifier, inverseKey, identifier, silenceNotifications) {
    if (!graph.has(inverseIdentifier, inverseKey)) {
      return;
    }
    const relationship = graph.get(inverseIdentifier, inverseKey);
    (true && !(!isImplicit(relationship)) && (0, _debug.assert)(`expected no implicit`, !isImplicit(relationship))); // For remote members, it is possible that inverseRecordData has already been associated to
    // to another record. For such cases, do not dematerialize the inverseRecordData
    if (!isBelongsTo(relationship) || !relationship.localState || identifier === relationship.localState) {
      /*#__NOINLINE__*/removeDematerializedInverse(graph, relationship, identifier, silenceNotifications);
    }
  }
  function clearRelationship(relationship) {
    if (isBelongsTo(relationship)) {
      relationship.localState = null;
      relationship.remoteState = null;
      relationship.state.hasReceivedData = false;
      relationship.state.isEmpty = true;
    } else {
      relationship.remoteMembers.clear();
      relationship.remoteState = [];
      relationship.additions = null;
      relationship.removals = null;
      relationship.localState = null;
    }
  }
  function removeDematerializedInverse(graph, relationship, inverseIdentifier, silenceNotifications) {
    if (isBelongsTo(relationship)) {
      const localInverse = relationship.localState;
      if (!relationship.definition.isAsync || localInverse && isNew(localInverse)) {
        // unloading inverse of a sync relationship is treated as a client-side
        // delete, so actually remove the models don't merely invalidate the cp
        // cache.
        // if the record being unloaded only exists on the client, we similarly
        // treat it as a client side delete
        if (relationship.localState === localInverse && localInverse !== null) {
          relationship.localState = null;
        }
        if (relationship.remoteState === localInverse && localInverse !== null) {
          relationship.remoteState = null;
          relationship.state.hasReceivedData = true;
          relationship.state.isEmpty = true;
          if (relationship.localState && !isNew(relationship.localState)) {
            relationship.localState = null;
          }
        }
      } else {
        relationship.state.hasDematerializedInverse = true;
      }
      if (!silenceNotifications) {
        notifyChange(graph, relationship.identifier, relationship.definition.key);
      }
    } else {
      if (!relationship.definition.isAsync || inverseIdentifier && isNew(inverseIdentifier)) {
        // unloading inverse of a sync relationship is treated as a client-side
        // delete, so actually remove the models don't merely invalidate the cp
        // cache.
        // if the record being unloaded only exists on the client, we similarly
        // treat it as a client side delete
        /*#__NOINLINE__*/
        removeIdentifierCompletelyFromRelationship(graph, relationship, inverseIdentifier);
      } else {
        relationship.state.hasDematerializedInverse = true;
      }
      if (!silenceNotifications) {
        notifyChange(graph, relationship.identifier, relationship.definition.key);
      }
    }
  }
  function removeCompletelyFromInverse(graph, relationship) {
    const {
      identifier
    } = relationship;
    const {
      inverseKey
    } = relationship.definition;
    forAllRelatedIdentifiers(relationship, inverseIdentifier => {
      if (graph.has(inverseIdentifier, inverseKey)) {
        removeIdentifierCompletelyFromRelationship(graph, graph.get(inverseIdentifier, inverseKey), identifier);
      }
    });
    if (isBelongsTo(relationship)) {
      if (!relationship.definition.isAsync) {
        clearRelationship(relationship);
      }
      relationship.localState = null;
    } else if (isHasMany(relationship)) {
      if (!relationship.definition.isAsync) {
        clearRelationship(relationship);
        notifyChange(graph, relationship.identifier, relationship.definition.key);
      }
    } else {
      relationship.remoteMembers.clear();
      relationship.localMembers.clear();
    }
  }
  function addPending(cache, definition, op) {
    const lc = cache[definition.kind] = cache[definition.kind] || new Map();
    let lc2 = lc.get(definition.inverseType);
    if (!lc2) {
      lc2 = new Map();
      lc.set(definition.inverseType, lc2);
    }
    let arr = lc2.get(op.field);
    if (!arr) {
      arr = [];
      lc2.set(op.field, arr);
    }
    arr.push(op);
  }
  function isReordered(relationship) {
    // if we are dirty we are never re-ordered because accessing
    // the state would flush away any reordering.
    if (relationship.isDirty) {
      return false;
    }
    const {
      remoteState,
      localState,
      additions,
      removals
    } = relationship;
    (true && !(localState) && (0, _debug.assert)(`Expected localSate`, localState));
    for (let i = 0, j = 0; i < remoteState.length; i++) {
      const member = remoteState[i];
      const localMember = localState[j];
      if (member !== localMember) {
        if (removals && removals.has(member)) {
          // dont increment j because we want to skip this
          continue;
        }
        if (additions && additions.has(localMember)) {
          // increment j to skip this localMember
          // decrement i to repeat this remoteMember
          j++;
          i--;
          continue;
        }
        return true;
      }

      // if we made it here, increment j
      j++;
    }
    return false;
  }
  function isStore(maybeStore) {
    return maybeStore._instanceCache !== undefined;
  }
  function getWrapper(store) {
    return isStore(store) ? store._instanceCache._storeWrapper : store;
  }
  function peekGraph(store) {
    return Graphs.get(getWrapper(store));
  }
  function graphFor(store) {
    const wrapper = getWrapper(store);
    let graph = Graphs.get(wrapper);
    if (!graph) {
      graph = new Graph(wrapper);
      Graphs.set(wrapper, graph);
      getStore(wrapper)._graph = graph;
      if ((0, _runtime.macroCondition)((0, _runtime.config)("/home/tj/crimeisdown-v3/node_modules/@ember-data/graph").env.DEBUG)) {
        if (getStore(wrapper).isDestroying) {
          throw new Error(`Memory Leak Detected During Teardown`);
        }
      }
    }
    return graph;
  }
});